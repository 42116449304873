import { Container } from "unstated"
import history from "../components/History"
import * as api from "../api"

class authenticationContainer extends Container {
    state = {
        isLoading: false,
        warnings: [],
        warningHeader: "",
        warningFooter: "",
        loggedIn: false,
        refreshTimer: null,
        refreshTime: 600000 //10 minutes
    }

    constructor() {
        super()
        this.initialize()
    }

    initialize = () => {
        this.setState({
            loggedIn: sessionStorage.getItem("LOGGED_IN") ? true : false
        })
        if (sessionStorage.getItem("LOGGED_IN")) {
            this.refreshToken();  //Should call refreshToken if the user refreshes the site
        }
    }

    checkResult = async (result) => {
        if (!result) {
            await this.setState({
                warnings: "There was an unexpected problem. Please try again.",
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter || "",
                isLoading: false
            })
            return false
        }

        if (!result?.succeeded) {
            await this.setState({
                warnings:
                    result?.warnings && result.warnings.length > 0
                        ? result.warnings
                        : ["There was an unexpected problem. Please try again."],
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter || "",
                isLoading: false
            })
            return false
        }
        if (result?.warnings?.length > 0) {
            await this.setState({
                warnings: result?.warnings,
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter || "",
                isLoading: false
            })
            return result?.succeeded
        }

        return true
    }

    initRefreshToken = async () => {
        this.setState({
            refreshTimer: setTimeout(() => {
                this.refreshToken()
            }, this.state.refreshTime)
        })
    }

    refreshToken = async () => {
        const payload = {
            refreshToken: sessionStorage.getItem("REFRESH_TOKEN")
        }

        const result = await api.refreshToken(payload)

        if (!this.checkResult(result)) {
            return
        }

        if (!result) {
            this.logout()
            return false
        }

        await sessionStorage.setItem("ACCESS_TOKEN", result.token)

        sessionStorage.setItem("REFRESH_TOKEN", result.refreshToken)

        this.initRefreshToken()
    }

    login = async (username, password) => {
        this.setState({
            isLoading: true
        })

        const payload = {
            email: username,
            password: password,
            rememberMe: false,
            siteMember: "broker"
        }

        const result = await api.login(payload)

        if (!result || !result?.succeeded) {
            this.setState({
                isLoading: false
            })
            if (result.isLockedOut) {
                return "User is locked out. Please try again in 15 minutes."
            } else {
                return "Incorrect username and/or password"
            }
        }

        await sessionStorage.setItem("ACCESS_TOKEN", result.token)

        await this.setState({
            isLoading: false
        })

        history.push("/two-factor-login")

        return "succeeded"
    }

    loginWith2fa = async (twoFactorCode) => {
        this.setState({
            isLoading: true
        })

        const payload = {
            twoFactorCode: twoFactorCode,
            returnUrl: ""
        }

        const result = await api.loginWith2fa(payload)

        if (!result || !result?.succeeded) {
            this.setState({
                isLoading: false
            })
            return "Incorrect code"
        }

        await sessionStorage.setItem("LOGGED_IN", "true")
        await sessionStorage.setItem("ACCESS_TOKEN", result.token)
        await sessionStorage.setItem("REFRESH_TOKEN", result.refreshToken)

        await this.setState({
            loggedIn: true,
            isLoading: false
        })

        this.initRefreshToken()

        history.push("/")

        return true
    }

    resend2faToken = async () => {
        const result = await api.resend2faToken()

        if (!this.checkResult(result)) {
            return
        }

        return result
    }

    logout = async () => {
        this.setState({
            isLoading: true
        })

        const payload = {
            refreshToken: sessionStorage.getItem("REFRESH_TOKEN")
        }

        if (payload.refreshToken) {
            const result = await api.logout(payload)

            if (!this.checkResult(result)) {
                return
            }
        }

        await sessionStorage.clear()

        clearTimeout(this.state.refreshTimer)

        history.push("/")

        this.setState({
            loggedIn: false,
            isLoading: false,
            refreshToken: ""
        })
    }

    forgotPassword = async (email) => {
        this.setState({
            isLoading: true
        })

        const payload = {
            email: email
        }

        const result = await api.forgotPassword(payload)

        this.setState({
            isLoading: false
        })

        if (result) {
            return true
        } else {
            return "User not found."
        }
    }

    resetPassword = async (data) => {
        this.setState({
            isLoading: true
        })

        const payload = {
            email: data.email,
            identityCode: data.identityCode,
            code: data.code,
            password: data.password,
            confirmPassword: data.confirmPassword
        }

        const result = await api.resetPassword(payload)

        if (!this.checkResult(result)) {
            return
        }

        this.setState({
            isLoading: false
        })

        return result && result?.succeeded === true
    }
}

export default authenticationContainer
