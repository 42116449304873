import { sendGet, sendPost } from "./generic"


export const reorderUndergroundStorageTanks = (payload)=> {
    return sendPost("UndergroundStorageTanks/Reorder", payload)
}

export const getUndergroundTankAges = ()=> {
    return sendGet("UndergroundStorageTanks/TankAges")
}

export const getUndergroundConstructions = ()=> {
    return sendGet("UndergroundStorageTanks/Constructions")
}

export const getUndergroundCapacities = ()=> {
    return sendGet("UndergroundStorageTanks/Capacities")
}

export const getUndergroundContents = ()=> {
    return sendGet("UndergroundStorageTanks/Contents")
}

export const getUndergroundDeductibles = ()=> {
    return sendGet("UndergroundStorageTanks/Deductibles")
}

export const getUndergroundPipeAges = ()=> {
    return sendGet("UndergroundStorageTanks/PipeAges")
}

export const getUndergroundLeakDetectionTypes = ()=> {
    return sendGet("UndergroundStorageTanks/LeakDetectionTypes")
}

export const getUndergroundPipeLineConstructions = ()=> {
    return sendGet("UndergroundStorageTanks/PipeLineConstructions")
}

export const updateUndergroundStorageTank = (undergroundStorageTankId, payload)=> {
    return sendPost("UndergroundStorageTanks/"+undergroundStorageTankId, payload)
}