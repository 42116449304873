/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import history from "../../components/History";

const componentStyle = css`
  margin-top: 15px;
`;
class ApplicationStatus extends React.Component {
  getApplicationPDF = () => {
    const { applicationsContainer } = this.props;
    applicationsContainer.getApplicationPDF(
      applicationsContainer.state.currentApplicationId
    );
  };

  render() {
    const { applicationsContainer } = this.props;
    return (
      <div css={componentStyle}>
        <div className="row">
          <div className="col">
            <h2>Application for Storage Tank Policy</h2>
          </div>
        </div>

        <div className="row">
          <div className="col">
            {applicationsContainer.state.status === "Declined" && (
              <p className="large">
                We're sorry your application for coverage has been declined. For
                additional assistance, please contact Heather Boyd{" "}
                <a href="mailto:hboyd@gaig.com">hboyd@gaig.com</a>.
              </p>
            )}
            {applicationsContainer.state.status === "UnderReview" && (
              <p className="large">
                Thank you for your submission! This application has been
                referred to underwriting for review. An underwriting
                representative from Great American will contact you regarding
                the details of this application.
              </p>
            )}
            {applicationsContainer.state.status === "Approved" && (
              <>
                <p className="large">
                  Your application has been approved! Please click on "View
                  Quote" button to access your detailed quote letter. Please
                  click on "Go to My Homepage" button to access quote and bind
                  coverage. Thank you.
                </p>

                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Premium</th>
                      <th>TRIA (Terrorism Coverage)</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{applicationsContainer.state.premium}</td>
                      <td>{applicationsContainer.state.triaCost}</td>
                      <td>{applicationsContainer.state.total}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>

        <div className="row button-group">
          <div className="col">
            {applicationsContainer.state.status === "Approved" && (
              <button
                className="btn btn-sm btn-primary"
                onClick={this.getApplicationPDF}
              >
                View Quote
              </button>
            )}
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                history.push("/");
              }}
            >
              Go To My Homepage
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplicationStatus;
