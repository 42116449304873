import { Container } from "unstated"
import * as api from "../api"

class BrokerCompaniesContainer extends Container {
    
    state = {
        isLoading: false,
        warnings: [],
        warningHeader: "",
        warningFooter: "",
        entityTypes: [],
    }

    constructor(){
        super()
        this.initialize()
    }

    initialize = async ()=>{
        this.setState({
            isLoading: true
        })

        await this.getEntityTypes()

        this.setState({
            isLoading: false
        })
    }

    checkResult = async (result)=>{

        if(!result){
            await this.setState({
                warnings: "There was an unexpected problem. Please try again.",
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }

        if(!result?.succeeded){
            await this.setState({
                warnings: (result?.warnings && result?.warnings?.length > 0) ? result.warnings : ["There was an unexpected problem. Please try again."],
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }
        if(result?.warnings?.length > 0){
            await this.setState({
                warnings: result.warnings,
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return result?.succeeded
        }

        return true
    }

    getEntityTypes = async ()=>{
        const result = await api.getEntityTypes()
        if(!this.checkResult(result)){
            return
        }
        
        this.setState({
            entityTypes: result?.items
        })
    }

}

export default BrokerCompaniesContainer