import { Container } from "unstated"
import * as api from "../api"

class RegistrationsContainer extends Container {
    
    state = {
        isLoading: false,
        warnings: [],
        warningHeader: "",
        warningFooter: "",
        registration: null,
        email: "",
        phoneNumber: ""
    }

    initialize = async ()=>{
    }

    checkResult = async (result)=>{

        if(!result){
            await this.setState({
                warnings: "There was an unexpected problem. Please try again.",
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }

        if(result && !result?.succeeded){
            await this.setState({
                warnings: (result?.warnings && result?.warnings?.length > 0) ? result.warnings : ["There was an unexpected problem. Please try again."],
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }
        if(result && result?.warnings?.length > 0){
            await this.setState({
                warnings: result.warnings,
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return result?.succeeded
        }

        return true
    }

    register = async (data)=>{

        this.setState({
            isLoading: true
        })
        
        const payload = {
            name: data.name,
            typeOfEntity: data.typeOfEntity,
            admittedProducerCode: data.admittedProducerCode,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            state: data.state,
            postalCode: data.postalCode,
            mainPhone: data.mainPhone,
            website: data.website,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            acceptedTermsAndConditions: data.acceptedTermsAndConditions,
            completeRegistrationUp: false,
            commission: data.commission ? data.commission : 20
        }
        
        const result = await api.register(payload)

        if(!this.checkResult(result)){
            return
        }

        if(result?.succeeded){
            await sessionStorage.setItem("ACCESS_TOKEN", result.token)
        }

        this.setState({
            isLoading: false
        })

        return result
    }
 
    sendPhoneNumberConfirmation = async (email, phoneNumber)=>{  
        
        await this.setState({
            email: email ? email : this.state.email,
            phoneNumber: phoneNumber ? phoneNumber : this.state.phoneNumber
        })
        
        const payload = ({
            email: email ? email : this.state.email,
            phoneNumber: phoneNumber ? phoneNumber : this.state.phoneNumber
        })

        const result = await api.sendPhoneNumberConfirmation(payload)

        if(!this.checkResult(result)){
            return
        }

        return result
    }

    confirmPhoneNumber = async (code)=>{

        this.setState({
            isLoading: true
        })

        const payload = {
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            code: code
        }

        const result = await api.confirmPhoneNumber(payload)

        if(!result || !result?.succeeded){
            this.setState({
                isLoading: false
            })
            return "Incorrect code"
        }

        await this.setState({
            isLoading: false
        })

        return true

    }
}

export default RegistrationsContainer