/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react/macro"
import history from "../../components/History"

const componentStyle = css`
    h2{
        margin-bottom: 15px;
    }

    button.anchor{
        margin-left: 3px;
        font-size: 14px;
    }
`
class ConfirmPhoneNumber extends React.Component{

    state={
        code: "",
        phoneNumber: "",
        error: "",
        updatePhoneNumberVisible: false,
        registered: false,
        codeResent: false
    }

    componentDidMount(){
        const { registrationsContainer } = this.props
        if(!registrationsContainer.state.email ||
           !registrationsContainer.state.phoneNumber){
               history.push("/")
        }
    }

    updateValue = (e)=>{
        e = e.currentTarget
        this.setState({
            [e.name]: e.value
        })
    }

    confirmPhoneNumber = async ()=>{

        this.setState({
            isLoading: true
        })

        const { registrationsContainer } = this.props

        const result = await registrationsContainer.confirmPhoneNumber(this.state.code)

        if(result !== true){
            this.setState({
                error: result,
                isLoading: false
            })
        }else{
            this.setState({
                registered: true,
                isLoading: false
            })
        }
    }

    toggleUpdatePhoneNumber = ()=>{
        this.setState({
            updatePhoneNumberVisible: this.state.updatePhoneNumberVisible ? false : true
        })
    }

    updatePhoneNumber = async ()=>{
        const { accountsContainer, registrationsContainer } = this.props

        if(!this.state.phoneNumber){
            this.setState({
                error: "Please enter a phone number"
            })
            return false
        }

        const result = await accountsContainer.updateAccountPhoneNumber(this.state.phoneNumber)

        if(result !== true){
            this.setState({
                error: result
            })
        }
        else{
            await registrationsContainer.sendPhoneNumberConfirmation(null, this.state.phoneNumber)
            this.toggleUpdatePhoneNumber()
        }
    }

    resendCode = async ()=>{
        this.setState({
            isLoading: true,
            codeResent: false
        })
        const { registrationsContainer } = this.props
        const result = await registrationsContainer.sendPhoneNumberConfirmation()

        if(result?.succeeded !== true){
            this.setState({
                error: "There was an error please try again"
            })
        }else{
            this.setState({
                isLoading: false,
                codeResent: true
            })
        }
    }
        
    render(){
        const { registrationsContainer } = this.props

        return(        
            <div  css={componentStyle}>
                {!this.state.registered && 
                <>
                    <div className="row">
                        <div className="col">
                            <h2>Confirm Phone Number</h2>
                            {!this.state.updatePhoneNumberVisible &&
                                <p>Please enter the code sent to {registrationsContainer.state.phoneNumber}. 
                                <button className="anchor" 
                                        onClick={this.toggleUpdatePhoneNumber}>
                                    Update phone number?
                                </button></p>
                            }                    
                        </div>
                    </div>
                    {!this.state.updatePhoneNumberVisible &&
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label htmlFor="code">Code:</label>
                                    <input type="text" 
                                            className="form-control form-control-sm" 
                                            id="code" 
                                            name="code"
                                            value={this.state.code}
                                            onChange={this.updateValue} />
                                </div>
                            </div>
                        </div>
                    }
                    
                    {this.state.updatePhoneNumberVisible &&
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="phoneNumber">Mobile Phone Number:</label>
                                    <input type="text" 
                                            className="form-control form-control-sm" 
                                            id="phoneNumber" 
                                            name="phoneNumber"
                                            value={this.state.phoneNumber}
                                            onChange={this.updateValue} />
                                </div>
                            </div>
                        </div>
                    }

                    {this.state.error !== "" &&
                        <div className="row">
                            <div className="col-4">
                                <p className="error">{this.state.error}</p>
                            </div>
                        </div>
                    }
                        
                    {!this.state.updatePhoneNumberVisible &&
                        <>
                        <div className="row button-group">
                            <div className="col-4">
                            <button className="btn btn-sm btn-primary float-right"
                                        onClick={this.confirmPhoneNumber}
                                        disabled={registrationsContainer.state.isLoading}>Submit</button>
                            </div>
                        </div>
                        
                        {this.state.codeResent &&
                            <div className="row">
                                <div className="col">
                                    <p>Code resent</p>
                                </div>
                            </div>
                        }
                    
                        <div className="row">
                            <div className="col">
                                <button className="anchor"
                                        onClick={this.resendCode}>
                                    Resend Code
                                </button>
                            </div>
                        </div>
                        </>
                    }

                    {this.state.updatePhoneNumberVisible &&
                        <div className="row button-group">
                            <div className="col-4">
                                
                                <button className="btn btn-sm btn-primary float-right"
                                        onClick={this.updatePhoneNumber}
                                        disabled={registrationsContainer.state.isLoading}>Update</button>
                                <button className="btn btn-sm btn-secondary float-right"
                                        onClick={this.toggleUpdatePhoneNumber}
                                        disabled={registrationsContainer.state.isLoading}>Cancel</button>

                            </div>
                        </div>
                    }
                </>
                }
                
                {this.state.registered === true &&
                    <div className="row" css={componentStyle}>
                        <div className="col">
                            <h2>Thank you!</h2>
                            <p className="directions margin-top">Thank you for your interest in Great American's TankAssure program. Your agency information has been received. A representative will be contacting you shortly regarding your enrollment request.</p>

                            <p>We look forward to working with you!</p>

                            <p>The Great American Insurance Group Storage Tank Team.</p>
                        </div>
                    </div>                
                }   
            </div>    
        )
    }   
}

export default ConfirmPhoneNumber