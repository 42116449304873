/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css`
    padding: 15px;
    background: #f2f2f2;
    text-align: center;
    height: 100%;    
    border-top: solid 2px #cc0000;
    border-bottom: solid 1px #cc0000;

    h3{
        margin-bottom: 15px !important;
    }

    p{
        margin: 0;
    }

    button.application{
        background-color: #063769;
        margin-bottom: 20px;
        color: #fff;
    }
`
class GetQuote extends React.Component{ 
        
    render(){
        const { applicationsContainer } = this.props

        return(
            <div css={componentStyle}>
                <h3>Get A Quote!</h3>
                <button className="btn btn-sm application"
                        onClick={applicationsContainer.newApplication}>
                        <i className="fa fa-file-text-o" aria-hidden="true"/> Click here to fill out an application today!
                </button>
                <p>(Note: 20% commission applies)</p>
            </div>
        )
    }   
}

export default GetQuote