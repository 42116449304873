/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { formatDate } from "../../helpers/FormatDate"
import ReviewApplication from "./ReviewApplications"

const componentStyle = css`   
`
class Policy extends React.Component{

    state = {
        id: "",
        namedInsuredId: "",
        namedInsured: "",
        policyNumber: "",
        dateEffectiveUtc: "",
        dateExpirationUtc: "",
        status: "",
        type: "",
    }

    state = {
        id: ""
    }

    componentDidMount(){
        const { policy } = this.props
        
        this.setState({
            id: policy.id
        })
    }

    getPolicyPDF = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.getPolicyPDF(this.state.id)
    }

    renewPolicy = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.renewPolicy(this.state.id)
    }

    getApplication = async ()=>{
        const { applicationsContainer } = this.props
        await applicationsContainer.viewApplication(
          this.state.id
        );

        applicationsContainer.setState({
            isLoading: false
        })
        this.setState({
            showApplicationInformation: true
        })
    }

    toggleViewApplication = ()=>{
        this.setState({
            showApplicationInformation: this.state.showApplicationInformation ? false : true
        })
    }
        
    render(){
        const { policy,isLatest } = this.props
        const { applicationsContainer } = this.props
        const curApp = applicationsContainer.state.currentApplication

        return(
            <>
            {
                this.state.showApplicationInformation &&
                    <ReviewApplication
                        applicationContainer = {applicationsContainer}
                        toggleViewApplication = {this.toggleViewApplication}
                        curApp = {curApp}
                     />
            }
            <tr css={componentStyle}>
                <td>{policy.friendlyId}</td>
                <td>{policy.namedInsured}</td>
                <td>{policy.policyNumber}</td>
                <td>{formatDate(policy.dateEffectiveUtc)} - {formatDate(policy.dateExpirationUtc)}</td>
                <td>{policy.status}</td>
                <td>
                    <button className="anchor text-center"
                            onClick={this.getApplication}
                            >
                        <i className="fa fa-search"></i>
                    </button>

                </td>
                <td>
                    <button className="anchor"
                            onClick={this.getPolicyPDF}>
                        Download
                    </button>
                </td>
                <td>
                    <button  className="anchor"
                            onClick={this.renewPolicy} disabled={!isLatest || policy.isRenewed}>
                        Renew
                    </button>
                </td>
            </tr>
        </>
        )
    }   
}

export default Policy