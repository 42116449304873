
export const poBoxRegex =
    /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i

    export const getReasons = (reasonsArray) => {
    const reasons = {
      namedInsuredInformation: ["Coverages unavailable in this state"],
      requirementQuestions: ["An answer on a question that requires referral"],
      aboveOrUndergroundStorageTanks: [
        "An item in storage tank schedule that requires referral",
        "A certificate for a tank in a state that requires review",
      ],
      isMultiYearTerm: ["Multi year term"],
      coverages: [
        "A state that must purchase coverage C",
        "Coverage limits over $5,000,000",
      ],
      undergroundStorageTanks: [
        "A pipe age that does not match your tank age",
        "Line Construction: Single-wall steel",
        "Line Construction: Other",
      ],
    }

    let result =  Object.keys(reasons).filter((reason) =>
      reasonsArray.some((r) => reasons[reason].includes(r))
    )

    return result
}