/** @jsxImportSource @emotion/react */
import React from "react"
import Modal from "../../components/Modal"

class PopupNotification extends React.Component{

    confirmNotification = async ()=>{
        const { confirmNotification, notification } = this.props
        confirmNotification(notification.id)
    }

    render(){        
        const { notification } = this.props

        return(
            <Modal>
                <div className="row">
                    <div className="col">
                        <p>{notification.message}</p>
                    </div>
                </div>
                <div className="row button-group">
                    <div className="col">
                        <button className="btn btn-sm btn-primary float-right"
                                onClick={this.confirmNotification}>
                            OK
                        </button>
                    </div>
                </div>                    
            </Modal>
        )
    }   
}

export default PopupNotification