/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css`
        background-color: #f2f2f2;
        padding: 10px;
        padding-right: 15px;
        text-align: right;
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-left: -20px;
        height: 40px;
        filter: drop-shadow(4px 0 4px rgba(0,0,0,.2));
        z-index: 10;

        p{
            font-size: 16px;
        }
`
class Total extends React.Component{
    
    render(){
        const { applicationsContainer } = this.props
        return(
            <div className="row" css={componentStyle}>
                <div className="col">
                    <p className="total">Total: {applicationsContainer.state.total}</p>
                </div>
            </div>
        )
    }   
}

export default Total