/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import AbovegroundBindingQuestionTable from "./AbovegroundBindingQuestionTable"

const componentStyle = css`
    margin-top: 15px;
`
class AbovegroundBindingQuestions extends React.Component{

    render(){
        const { applicationsContainer } = this.props
        const questionSets = applicationsContainer.state.abovegroundBindingQuestions

        return(
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        <h3>Aboveground Storage Tanks</h3>
                        {questionSets.map((questionSet, index)=>(
                            ((questionSet.tanks.length > 0) &&
                                <AbovegroundBindingQuestionTable key={index}
                                                                 questionSet={questionSet}
                                                                 applicationsContainer = {applicationsContainer} />
                            )
                        ))}                        
                    </div>
                </div>
            </div>
        )
    }   
}

export default AbovegroundBindingQuestions