/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react/macro"
import Modal from "../../components/Modal"

const componentStyle = css`
`
class TermsAndConditions extends React.Component{
        
    agreeToTerms = ()=>{
        this.props.agreeToTerms()
    }
    
    render(){
       
        return(
            <Modal close={this.props.close}>  
                <div css={componentStyle}>
                    <div className="row">
                        <div className="col">
                            <h2>Privacy Policy and Terms &amp; Conditions</h2>
                            
                            <p>In exchange for the right to access and use the System, You agree to the following terms and conditions.</p>
                            
                            <ol>
                                <li>If You access the System on behalf of an organization or company, You bind that organization or company to these terms and You affirm that You have the legal authority to do so.</li>
                                <li>You agree that the Company is the owner of or has the right to control all data contained in the System ("Data"). You agree that the Data is the proprietary and confidential information of the Company, which may include nonpublic personal information ("NPI"). You will preserve and protect the confidentiality of all Data with at least the same standard of care with which You protect Your own confidential information and in no event less than a commercially reasonable standard of care. You agree to immediately notify Company if You inadvertently access any Data You reasonably believe may be protected by attorney-client privilege, not copy, use or disclose such information, and immediately destroy such information upon request of Company.</li>
                                <li>For the purposes of this Access Agreement, "NPI" is defined as nonpublic personal information of Company's and Your (or Your company's) employees, contract holders, insureds, customers, and consumers and/or of Company and Your subsidiaries or affiliates including, but not limited to, Social Security Numbers, identification card numbers, credit or debit card numbers, financial account numbers, claims information, health information, and biometric records. You agree to comply fully with all laws and regulations governing the collection, use, and disclosure of NPI ("Privacy Laws"). You agree not to disclose any NPI that You access through the System, except as permitted by applicable Privacy Laws and this Access Agreement.</li>
                                <li>You will access and use the System and Data for only the following purposes (collectively, the "Permitted Purpose"). If You access the System on behalf of an insurance agency or producer: (i) for the purposes of administration and servicing of insurance policies written by the Company; and (ii) for the benefit of the Company. If You access the system on behalf of a Company policyholder or client: (i) for the purpose of managing Your insurance policies written by, or other product or service purchased from, the Company; and (ii) for Your benefit.</li>
                                <li>You will not (i) use and access the System and Data for any purpose that is unlawful; (ii) use the system for any purpose other than the Permitted Purpose; and (iii) copy, redistribute, use or publish any Data for any purpose other than the Permitted Purpose.</li>
                                <li>You will not knowingly submit or enter false, incomplete, or misleading information into the System.</li>
                                <li>The Company does not warrant or guarantee the accuracy of the Data. The Company specifically disclaims direct and indirect liability for any loss, damage, or expense incurred by You arising from or related to (i) Your access to or use of the Systems or Data; and (ii) any actual or alleged infringement of patents or intellectual property rights of other parties related to the Systems or Data.</li>
                                <li>You agree to indemnify, hold harmless, and defend the Company against any loss, damage, or expense, including attorney fees, incurred by the Company as a result of (i) Your negligent or more culpable act or omission arising from use of the System and Data; or (ii) Your breach of any of the terms of this Access Agreement.</li>
                                <li>You will (i) limit access to the System to only Your employees and contractors who need to access the System for the Permitted Purpose ("Authorized Employees"); (ii) prohibit Authorized Employees from sharing account access; and (iii) ensure Authorized Employees protect their User ID and Password using industry best practices.</li>
                                <li>If You access the System on behalf of an insurance agency or producer, you agree to the following data security terms and conditions:
                                    <ol>
                                        <li>You represent, warrant and covenant that Your data security policies, procedures and controls: (i) comply with all applicable laws; (ii) include technical, physical and administrative or organizational safeguards designed to: (a) secure and maintain confidentiality and security of NPI in Your possession; (b) protect against any anticipated threats or hazards to the security and integrity of NPI provided to You; (c) protect against unauthorized processing, theft, loss, use, disclosure, acquisition or access to NPI provided to You; and (d) protect NPI using industry standard encryption tools; (iii) include regular testing or otherwise monitoring of the effectiveness of the safeguards; and (iv) require any sub-contractors who have or may have access to NPI to employ data security policies, procedures and controls at least as restrictive as those employed by You.</li>
                                        <li>Upon the provision of reasonable notice by the Company, the Company or its designee or a regulator may undertake a security assessment and/or audit of Your systems and information security measures: (i) at least once per year during the term of the Agreement; and (ii) following an information security incident.</li>
                                        <li>You will immediately notify the Company in writing of: (a) any problems, system malfunction or security incident which impacts the Company's operations, impacts the Company's service to its customers, or may have a significant effect on Your ability to perform its obligations under this Agreement; or (b) any use or disclosure of NPI not authorized by this Agreement, including any reasonable belief that an unauthorized individual or other entity has accessed NPI. Upon the Company's written request, You agree to be responsible for notification of individuals in the event of unauthorized release of NPI or other event requiring notification and to perform such obligations in compliance with applicable law. In the event notification is required under this section, You will cooperate with the Company's investigation of any such problems including, but not limited to, providing the Company with information adequate to determine the root cause and impact of any such problems as reasonably requested by the Company.</li>
                                    </ol>
                                </li>
                                <li>You agree to the terms and conditions of Company's privacy which may be found at https://www.greatamericaninsurancegroup.com/contact/privacy. Company may update the privacy policy at any time without notice.  It is Your obligation to periodically review Company’s privacy policy to understand any updates. </li>
                                <li>Your access to the System does not confer a license to use the System and the Company may terminate this Access Agreement and Your access to the System, in whole or in part, at any time, with or without cause.</li>
                                <li>This Access Agreement shall be governed, construed, and enforced in accordance with the laws of the State of Ohio.</li>
                                <li>In the event of a conflict in terms between this Access Agreement and any other agreement governing access to the System, this Access Agreement shall control. However, if any other agreement between the Company and You governing access to the System contains any obligations of Yours that are in addition to or are more restrictive on You than those set forth in this Access Agreement, such additional or more restrictive obligations shall control.</li>
                                <li>You shall send any notice required to be provided to the Company under this Access Agreement to: Great American Insurance Company, Attn: General Counsel, 301 East Fourth Street, Cincinnati, Ohio 45202.</li>
                            </ol>

                            <p>To indicate that You agree to this Access Agreement, click the box labeled "Agree". If You do not agree to this Access Agreement, You will not be permitted to access the System.</p></div>
                    </div>
                    <div className="row button-group">
                        <div className="col">
                            <button className="btn btn-sm btn-primary float-right"
                            onClick={this.agreeToTerms}>Agree</button>      
                        </div>
                    </div>
                                      
                </div>
            </Modal>
        )
    }   
}

export default TermsAndConditions