import { sendPost, sendGet } from "./generic";

export const updateAccount = (agencyId, payload) => {
  return sendPost("Account/" + agencyId, payload);
};

export const updatePassword = (accountId, payload) => {
  return sendPost("Account/" + accountId + "/UpdatePassword", payload);
};

export const sendPhoneNumberConfirmation = (payload) => {
  try {
    return sendPost("Account/SendPhoneNumberConfirmation", payload);
  } catch (e) {
    return;
  }
};

export const confirmPhoneNumber = (payload) => {
  return sendPost("Account/ConfirmPhoneNumber", payload);
};

export const updateAccountInfo = (payload) => {
  return sendPost("Account/UserInfo", payload);
};

export const resend2faToken = () => {
  return sendPost("Account/Send2faToken");
};

export const getCurrentAccount = () => {
  return sendGet("Account/Current");
};

export const getBrokerCompanies = (id) => {
  return sendGet("BrokerCompanies/" + id);
};
