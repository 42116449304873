import { Container } from "unstated"
import * as api from "../api"

class NotificationsContainer extends Container {
    
    state = {
        isLoading: false,
        warnings: [],
        warningHeader: "",
        warningFooter: "",
        notifications: []
    }

    constructor(){
        super()
        this.initialize()
    }

    initialize = async ()=>{
    }

    checkResult = async (result)=>{

        if(!result){
            await this.setState({
                warnings: "There was an unexpected problem. Please try again.",
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }

        if(!result?.succeeded){
            await this.setState({
                warnings: (result?.warnings && result?.warnings.length > 0) ? result.warnings : ["There was an unexpected problem. Please try again."],
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return false
        }
        if(result?.warnings?.length > 0){
            await this.setState({
                warnings: result.warnings,
                warningHeader: result?.warningHeader || "Error",
                warningFooter: result?.warningFooter ||"",
                isLoading: false
            })
            return result?.succeeded
        }

        return true
    }

    getNotifications = async ()=>{
        const result = await api.getNotifications()

        if(!this.checkResult(result)){
            return
        }
        
        this.setState({
            notifications: result?.notifications || []
        })
    }

    confirmNotification = async (id)=>{
        const result = await api.confirmNotification(id)

        if(!this.checkResult(result)){
            return
        }
    }
    

   
}

export default NotificationsContainer