/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { sortableContainer, SortableElement } from "react-sortable-hoc"
import Location from "./Location"

const SortableItem = SortableElement(Location);

const SortableContainer = sortableContainer(({children}) => {
    return <tbody id="sortableContainer">{children}</tbody>
})

const componentStyle = css`
    margin-top: 30px;

    table{
        margin-top: 10px;
    }
`
class Locations extends React.Component{ 

    state = {
        file: null,
        fileName: ""
    }

    componentDidMount = async ()=>{
        const { applicationsContainer } = this.props
        await applicationsContainer.getLocations()
    }

    addLocation = async ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.addLocation()
    }

    getHelperContainer = ()=>{
        return document.querySelector('#sortableContainer')
    }

    onSortEnd = ({oldIndex, newIndex})=> {
        const { applicationsContainer } = this.props
        applicationsContainer.updateLocationsOrder(oldIndex, newIndex)
    }

    handleFileUpload = async (e)=>{
        e = e.currentTarget
        this.setState({
            file: e.files[0],
            fileName: e.files[0].name
        })
    }

    uploadFile = async ()=>{
        const { applicationsContainer } = this.props

        if(this.state.file){
            await applicationsContainer.uploadLocationsFile(this.state.file)
        }

        this.setState({
            file: null,
            fileName: ""
        })
    }
        
    render(){
        const { applicationsContainer } = this.props
        
        return(
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        <h3>Location Schedule</h3>
                        <p className="directions">
                            <strong>Directions</strong>: Please provide complete address for each location where coverage is being requested for a storage tank or upload an excel of all your locations. Click here to download the Location Schedule Excel template: <a href="/docs/LocationSchedule.xlsm">LocationSchedule.xlsm</a>.                       
                        </p>
                    </div>
                </div>
                <div className="row fileUpload margin-top">
                    <div className="col-sm-4">
                        <div className="form-group">     
                            <div className="custom-file float-left">
                                <input type="file" 
                                    className="custom-file-input form-control-sm" 
                                    id="file"
                                    name="file"
                                    onChange={this.handleFileUpload} />
                                <label className="custom-file-label col-form-label-sm" 
                                    htmlFor="file">
                                    {this.state.fileName ? this.state.fileName : "Choose File"}
                                </label>
                            </div>
                        
                            <button className="btn btn-sm btn-primary float-left upload"
                                    onClick={this.uploadFile}>
                                Upload
                            </button>
                        </div>
                    </div>
                    <div className="col-12 macro-direction">
                        <p>Please be sure your Excel settings are set to "Enable all macros" before pulling the spreadsheet to ensure all information uploads properly</p>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col">
                        <table className="table table-sm table-striped sortable-table">
                            <thead>
                                <tr>
                                    <th className="action"></th>
                                    <th>Location Number</th>
                                    <th>Site Use<span className="required">*</span></th>
                                    <th>Street Address<span className="required">*</span></th>
                                    <th>City<span className="required">*</span></th>
                                    <th>State<span className="required">*</span></th>
                                    <th>Zip Code<span className="required">*</span></th>
                                    <th className="action"></th>
                                </tr>
                            </thead>
                            <SortableContainer onSortEnd={this.onSortEnd}
                                               lockAxis="y"
                                               useDragHandle
                                               lockToContainerEdges
                                               helperContainer={this.getHelperContainer}
                                               helperClass="sortable-body">
                                {applicationsContainer.state.locations.map((location, index)=>(
                                    <SortableItem key={location}
                                                  index={index}
                                                  location={location}
                                                  applicationsContainer={applicationsContainer} />
                                ))}
                            </SortableContainer>
                        </table>
                        <div className="sortable-table-border-bottom-fix" />
                        <button className="btn btn-sm btn-primary"
                                onClick={this.addLocation}>
                            Add Location
                        </button>
                    </div>                    
                </div>
            </div>
        )
    }   
}

export default Locations