import { sendGet } from "./generic"

export const getPolicies = (page, numberOfRecords, orderBy, descending)=>{
    return sendGet("Policies/"+ page + "/" + numberOfRecords + "/" + orderBy + "/" + descending)
}

export const getPolicyPDF = (id)=>{
    return sendGet("Policies/"+ id + "/Pdf")
}

export const renewPolicy = (id)=>{
    return sendGet("Policies/"+ id + "/Renew")
}