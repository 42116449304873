import { sendGet, sendPost } from "./generic"

export const getNotifications = ()=> {
    return sendGet("Notifications")
}

export const confirmNotification = (id)=> {
    return sendPost("Notifications/"+id+"/Confirm")
}


