/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css`

    li.page-item.active a{
        background-color: #7694aa;
        border-color: #7694aa;
    }

    ul.pagination{
        margin-bottom: 0;
    }
`
class Pagination extends React.Component{ 

    navigate = async (e, page)=>{
        e.preventDefault()

        const { changePage } = this.props
        
        changePage(page)

        return false
    }

    render(){

        const { totalPages, currentPage } = this.props

        const pages = []

        for(let i=0; i < totalPages; i++){
            pages.push(<li className={"page-item" + (currentPage === i+1 ? " active" : "")}
                           key={i+1}>
                           <a className="page-link" 
                              href="/"
                              onClick={(e)=>this.navigate(e, i+1)}>{i+1}</a>
                       </li>)
        }

        return(  
            <> 
            {totalPages > 1 &&
                <nav css={componentStyle} className="float-right">
                    <ul className="pagination pagination-sm">
                        <li className="page-item">
                            <a className="page-link" 
                            href="/"
                            onClick={(e)=>this.navigate(e, 1)}>
                                <span>&laquo;</span>
                            </a>
                        </li>

                        {pages}
                        
                        <li className="page-item">
                            <a className="page-link" 
                            href="/"
                            onClick={(e)=>this.navigate(e, totalPages)}>
                                <span>&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            }             
            </>
        )
    }   
}

export default Pagination