/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import { getRecordsPerPage } from "../../constants/Pagination"
import Application from "./Application"
import Pagination from "../../components/Pagination"
import TableSort from "../../components/TableSort"


const componentStyle = css`
    margin-top: 15px;

    button.show-all{
        font-size: 14px;
        line-height: 14px;
        display: inline-block;
        margin-top: 5px;
    }

    .results{
        clear: both;
    }
`
class Applications extends React.Component{ 

    state = {
        totalRecords: 0,
        recordsPerPage: getRecordsPerPage(),
        totalPages: 0,
        currentPage: 1,
        orderBy: "NamedInsuredName",
        showAll: false,
        sort: {
            FriendlyId: null,
            NamedInsured: "asc",
            CustomerNumber: null,
            DateStarted: null,
            DateSubmitted: null,
            Version: null,
            Status: null
        }
    }

    componentDidMount = ()=>{        
        this.getApplications()
    }

    toggleShowAll = async ()=>{
        await this.setState({
            showAll: this.state.showAll ? false : true,
            currentPage: 1
        })

        this.getApplications()
    }

    getApplications = async()=>{
        const { applicationsContainer } = this.props
        const totalRecords = await applicationsContainer.getApplications(this.state.currentPage,
                                                                         getRecordsPerPage(),
                                                                         this.state.orderBy,
                                                                         this.state.sort[this.state.orderBy] === "asc" ? true : false,
                                                                         this.state.showAll)
        await this.setState({
            totalRecords: totalRecords,
            totalPages: Math.ceil(totalRecords/this.state.recordsPerPage)
        })
    }

    sortTable = async (orderBy, direction)=>{
        let tempSort = {}
        
        for (const key of Object.keys(this.state.sort)) {
            tempSort[key] = null;
        }
        
        if(!direction){
            tempSort[orderBy] = this.state.sort[orderBy] === "asc" ? "desc" : "asc"
        }else{
            tempSort[orderBy] = direction
        }    
                
        await this.setState({
            orderBy: orderBy,
            sort: tempSort,
            currentPage: 1
        })

        this.getApplications()
    }

    changePage = async (page)=>{
        await this.setState({
            currentPage: page
        })

        this.getApplications()
    }

    render(){
        const { applicationsContainer, toggleComments, accountsContainer } = this.props

        return(
            <div css={componentStyle}>
                <h3 className="float-left">My Applications</h3>
                {(applicationsContainer.state.applications.length > 90) &&
                    <button className="anchor show-all float-right"
                            onClick={this.toggleShowAll}>
                        {!this.state.showAll ? "Show All" : "Show Last 90 Days"}
                    </button>
                }                
                <div className="row results">
                    <div className="col">
                        <table className="table table-sm table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("FriendlyId")}
                                                    direction={this.state.sort.FriendlyId}>
                                            ID Number
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("NamedInsured")}
                                                    direction={this.state.sort.NamedInsured}>
                                            First Name Insured
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("DateStarted")}
                                                    direction={this.state.sort.DateStarted}>
                                            Date Started
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("DateSubmitted")}
                                                    direction={this.state.sort.DateSubmitted}>
                                            Date Submitted
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("Version")}
                                                    direction={this.state.sort.Version}>
                                            Version
                                        </TableSort>
                                    </th>
                                    <th>
                                        <TableSort onClick={()=>this.sortTable("Status")}
                                                    direction={this.state.sort.Status}>
                                            Status
                                        </TableSort>
                                    </th>
                                    <th>View Application</th>
                                    <th>View Comments</th>
                                    <th>Continue Application</th>
                                    <th>View Quote</th>
                                    <th>Bind Your Quote</th>
                                    <th>Clone Application</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applicationsContainer.state.applications.map(application=>(
                                    <Application key={application.id}
                                                 applicationsContainer={applicationsContainer}
                                                 application={application}
                                                 toggleComments={toggleComments}
                                                 getApplications={this.getApplications}
                                                 accountsContainer={accountsContainer}
                                                 />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {(this.state.totalRecords > 1) &&
                    <div className="row">
                        <div className="col-sm-12">
                            <Pagination totalPages={this.state.totalPages}
                                        currentPage={this.state.currentPage}
                                        changePage={this.changePage} />
                        </div>
                    </div>
                }                
            </div>    
        )
    }   
}

export default Applications