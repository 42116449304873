/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css`
    padding: 15px;
    background: #f2f2f2;
    text-align: center;
    height: 100%;
    border-top: solid 2px #cc0000;
    border-bottom: solid 1px #cc0000;

    h3 {
        margin-bottom: 15px !important;
    }

    p:last-child {
        margin-bottom: 0;
    }
`
class HelpCenter extends React.Component {
    render() {
        return (
            <div css={componentStyle}>
                <h3>Help Center</h3>
                <p>
                    General Help:
                    <br />
                    Phone: 857-345-0786
                    <br />
                    Email: <a href="mailto:hboyd@gaig.com">hboyd@gaig.com</a>
                </p>
                <p>
                    Claims Help:
                    <br />
                    Email: <a href="mailto:EnvDiv-Claims@GAIG.com">EnvDiv-Claims@GAIG.com</a>
                </p>
            </div>
        )
    }
}

export default HelpCenter
