/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css`
    i{
        cursor: pointer;
    }
`
class AdditionalInsured extends React.Component{

    state ={
        id: "",
        name: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        isTankOwner: "",
        type: ""
    }

    componentDidMount(){
        const { additionalInsured, type, applicationsContainer } = this.props

        const ai = applicationsContainer.state["additional" + (type === "Named" ? "Named" : "")+"InsuredMap"].get(additionalInsured)

        this.setState({
            id: ai.id,
            name: ai.name,
            addressLine1: ai.addressLine1 ? ai.addressLine1 : "",
            addressLine2: ai.addressLine2,
            city: ai.city ? ai.city : "",
            state: ai.state ? ai.state : "",
            postalCode: ai.postalCode ? ai.postalCode : "",
            isTankOwner: (ai.isTankOwner === true || ai.isTankOwner === "yes") ? "yes" : (ai.isTankOwner === false || ai.isTankOwner === "no") ? "no" : null,
            type: type
        })
    }

    updateValue = async (e)=>{
        e = e.currentTarget
        let name = e.name
        const value = e.value

        if(name.includes("owner")){
            name = "isTankOwner"
        }

        await this.setState({
            [name]: value
        })

        if(name === "isTankOwner" || name === 'state'){
            this.updateAdditionalInsured()
        }
    }

    updateAdditionalInsured = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.updateAdditionalInsured(this.state)
    }

    deleteAdditionalInsured = async ()=>{
        const { applicationsContainer, type, resetError } = this.props
        
        let confirm = window.confirm("Are you sure you want to delete entity " + this.state.name + "?")
        if(confirm){
            await applicationsContainer.deleteAdditionalInsured(this.state.id, type)
            resetError()
        }
    }
        
    render(){
        const { applicationsContainer, type } = this.props
        const isValidating = applicationsContainer.state.isValidating
        
        return(            
            <tr css={componentStyle}>
                <td>
                    <div className="form-group">
                        <input type="text" 
                               className={"form-control form-control-sm" + ((isValidating && this.state.name === "") ? " required" : "")}
                               id="name" 
                               name="name"
                               onChange={this.updateValue}
                               onBlur={this.updateAdditionalInsured}
                               value={this.state.name}
                               placeholder="Enter Name" />
                    </div>
                </td>
                {type === "Named" &&
                <>
                <td>
                    <div className="form-group">
                        <input type="text" 
                               className={"form-control form-control-sm" + ((isValidating && this.state.addressLine1 === "") ? " required" : "")}
                               id="addressLine1" 
                               name="addressLine1"
                               value={this.state.addressLine1}
                               onChange={this.updateValue}
                               onBlur={this.updateAdditionalInsured}
                               placeholder="Enter Address Line 1"/>
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <input type="text" 
                               className="form-control form-control-sm"
                               id="addressLine2" 
                               name="addressLine2"
                               value={this.state.addressLine2}
                               onChange={this.updateValue}
                               onBlur={this.updateAdditionalInsured}
                               placeholder="Enter Address Line 2" />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <input type="text" 
                               className={"form-control form-control-sm" + ((isValidating && this.state.city === "") ? " required" : "")}
                               id="city" 
                               name="city"
                               value={this.state.city}
                               onChange={this.updateValue}
                               onBlur={this.updateAdditionalInsured}
                               placeholder="Enter City" />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <select className={"form-control form-control-sm" + ((isValidating && this.state.state === "") ? " required" : "")}
                                id="state"
                                name="state"
                                value={this.state.state}
                                onChange={this.updateValue}>                            
                            <option value="">--- Select State ---</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="VI">Virgin Islands</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <input type="text" 
                               className={"form-control form-control-sm" + ((isValidating && this.state.postalCode === "") ? " required" : "")}
                               id="postalCode" 
                               name="postalCode"
                               value={this.state.postalCode}
                               onChange={this.updateValue}
                               onBlur={this.updateAdditionalInsured}
                               placeholder="Enter Zip Code" />
                    </div>
                </td>
                </>
                }
                <td>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" 
                               type="radio" 
                               id={"owner-"+this.state.id+"-Yes"}
                               name={"owner-"+this.state.id}                               
                               value="yes"
                               checked={this.state.isTankOwner === "yes"}
                               onChange={this.updateValue} />
                        <label className={"form-check-label" + ((isValidating && this.state.isTankOwner === null) ?" required" : "")}
                               htmlFor={"owner-"+this.state.id+"-Yes"}>
                            Yes
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" 
                               type="radio" 
                               id={"owner-"+this.state.id+"-No"}
                               name={"owner-"+this.state.id}                               
                               value="no"
                               checked={this.state.isTankOwner === "no"}
                               onChange={this.updateValue} />
                        <label className={"form-check-label" + ((isValidating && this.state.isTankOwner === null) ?" required" : "")}
                               htmlFor={"owner-"+this.state.id+"-No"}>
                            No
                        </label>
                    </div>
                </td>
                <td className="action">
                    <i className="fa fa-trash"
                       onClick={()=>{this.deleteAdditionalInsured()}} />
                </td>
            </tr>
        )
    }   
}

export default AdditionalInsured