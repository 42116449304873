/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"

const componentStyle = css`
`

class RequirementQuestion extends React.Component{

    state={
        id: "",
        templateId: "",
        label: "",
        value: null
    }

    componentDidMount = async()=>{        
        const { applicationsContainer, requirementQuestion } = this.props

        const rq = applicationsContainer.state.requirementQuestionsMap.get(requirementQuestion)

        this.setState({
            id: rq.id,
            templateId: rq.templateId,
            label: rq.label,
            value: rq.value
        })
    }

    updateValue = async (e)=>{
        e = e.currentTarget

        await this.setState({
            value: e.value
        })

        this.updateRequirementQuestion()
    }

    updateRequirementQuestion = async ()=>{
        const { applicationsContainer } = this.props
        const id = await applicationsContainer.updateRequirementQuestion(this.state)
        this.setState({
            id: id
        })
    }
        
    render(){
        const { applicationsContainer, index } = this.props
        const isValidating = applicationsContainer.state.isValidating

        return(
            <div className="form-group" css={componentStyle}>
                <label className={((isValidating && this.state.value === "") ?" required" : "")}>{index + 1}. {this.state.label}<span className="required">*</span></label>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" 
                           type="radio" 
                           id={this.state.templateId + "Yes"} 
                           name={this.state.templateId}                                 
                           value="yes"
                           checked={this.state.value === "yes"}
                           onChange={this.updateValue} />
                    <label className="form-check-label"
                           htmlFor={this.state.templateId + "Yes"}>
                        Yes
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" 
                        type="radio" 
                        id={this.state.templateId + "No"}  
                        name={this.state.templateId}                               
                        value="no"
                        checked={this.state.value === "no"}
                        onChange={this.updateValue} />
                    <label className="form-check-label"
                           htmlFor={this.state.templateId + "No"}>
                        No
                    </label>
                </div>
            </div>
        )
    }   
}

export default RequirementQuestion