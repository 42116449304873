export const formatDate = (date)=>{

    if(date){
        if(typeof(date) === "object"){
            let month = "" + (date.getMonth() + 1)
            let day = "" + date.getDate()
            const year = "" + date.getFullYear()
            
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [month, day, year].join('/');
        }
        
        date = date.toString()
        return date.substr(5,2)+"/"+date.substr(8,2)+"/"+date.substr(0,4)
    }
    return ""
}

export const formatDateWithTime = (date)=>{
    if(date){        
        const month = date.substr(5,2)
        const day = date.substr(8,2)
        const year = date.substr(0,4)
        let hour = date.substr(11,2)
        let meridian = "AM"

        if(hour > 12){
            hour = hour -12
            meridian = "PM"
        }

        const minutes = date.substr(13,3)
        
        return month + "/" + day + "/" +  year + " " + hour + minutes + " " + meridian
    }
    return ""
}

export const formatDateWithLongMonth = (date,)=>{

    const months = ["January", 
                    "February", 
                    "March",
                    "April", 
                    "May", 
                    "June", 
                    "July",
                    "August", 
                    "September", 
                    "October",
                    "November", 
                    "December"]

    if(date){
        return months[parseInt(date.substr(5,2))-1]+" "+date.substr(8,2)+", "+date.substr(0,4)
    }
    return ""
}