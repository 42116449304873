import Main from "./scenes/Main"
import { Provider } from "unstated"


function App() {
    return (
        <Provider>
            <Main />
        </Provider>        
    )
}

export default App