/** @jsxImportSource @emotion/react */
import React from "react"
import Modal from "../../components/Modal"

class Comments extends React.Component{ 
     
    render(){
        const { applicationsContainer, toggleComments } = this.props
        return(
            <Modal close={toggleComments}>
                <div className="row">
                    <div className="col">
                        <h3 className="margin-bottom">Comments</h3>

                        {applicationsContainer.state.comments.map(comment=>(
                            <p key={comment.id}>
                                <strong>{comment.from.firstName} {comment.from.lastName}</strong>: {comment.content}
                            </p>
                        ))}
                    </div>
                </div>
            </Modal>
        )
    }   
}

export default Comments