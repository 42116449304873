/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import Notification from "./Notification"
import PopupNotification from "./PopupNotification"

const componentStyle = css`
    padding: 15px;
    background: #f2f2f2;
    text-align: center;
    height: 100%;
    border-top: solid 2px #cc0000;
    border-bottom: solid 1px #cc0000;

    h3{
        margin-bottom: 15px !important;
    }

    .notifications{
        max-height: 155px;
        min-height: 155px;
        height: 155px;
        overflow-y: scroll;
    }

    .notification{
        margin-bottom: 10px
    }

    .notification:last-child{
        margin-bottom: 0
    }
`
class Notifications extends React.Component{

    state = {
        inlineNotifications: [],
        popupNotifications: [],
        confirmedPopupNotifications: [],
        currentPopupNotification: 1
    }

    componentDidMount = async ()=>{
        const { notificationsContainer } = this.props

        await this.setState({
            currentPopupNotification: 1
        })

        await notificationsContainer.getNotifications()

        let inlineNotifications = []
        let popupNotifications = []

        notificationsContainer.state.notifications.map(notification =>{
            if(notification.messageType === "Display"){
                inlineNotifications.push(notification)
            }else{
                popupNotifications.push(notification)
            }
            return true
        })

        this.setState({
            inlineNotifications: inlineNotifications,
            popupNotifications: popupNotifications
        })
    }

    confirmNotification = async (id)=>{
        const { notificationsContainer } = this.props

        await notificationsContainer.confirmNotification(id)

        await this.setState({
            currentPopupNotification: this.state.currentPopupNotification + 1
        })
    }

    render(){        
        return(
            <>
            <div css={componentStyle}>
                <h3>Notifications</h3>

                <div className="notifications">
                    {this.state.inlineNotifications.map(notification=>(
                        <Notification key={notification.id}
                                    message={notification.message} />
                    ))}
                </div>
            </div>

            {(this.state.popupNotifications.length > 0 &&
             this.state.currentPopupNotification <= this.state.popupNotifications.length) &&
                <PopupNotification confirmNotification={this.confirmNotification}
                                   notification={this.state.popupNotifications[this.state.currentPopupNotification -1]} />
            }
            </>
        )
    }   
}

export default Notifications