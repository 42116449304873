/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react/macro"
import { testValidEmail } from "../../helpers/ValidateEmail"
import history from "../../components/History"
import TermsAndConditions from "./TermsAndConditions"

const componentStyle = css`
    h2{
        margin-bottom: 15px;
    }

    h3{
        margin-top: 15px;
        margin-bottom: 15px;
    }

    button.anchor{
        margin-left: 3px;
    }
`
class Register extends React.Component{

    state={
        name: "",
        typeOfEntity: "",
        producerCode: "",
        admittedProducerCode: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        postalCode: "",
        mainPhone: "",
        website: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        acceptedTermsAndConditions: false,
        error: "",
        isValidating: false,
        termsAndConditionsVisible: false
    }

    toggleTermsAndConditions = ()=>{
        this.setState({
            termsAndConditionsVisible: this.state.termsAndConditionsVisible ? false : true
        })
    }

    updateValue = async (e)=>{
        e = e.currentTarget
        let value = e.value

        await this.setState({
            [e.name]: value
        })

        if(this.state.isValidating){
            this.validateForm()
        }
    }

    register = async ()=>{        
        if(!await this.validateForm()){
            return
        }

        const { registrationsContainer } = this.props
        const result = await registrationsContainer.register(this.state)

        if(!result?.succeeded){
            this.setState({
                error: result?.errorMessage || "Error",
            })
        }else{
            await registrationsContainer.sendPhoneNumberConfirmation(this.state.email, this.state.phoneNumber)
            history.push("/confirm-phone-number")
        }        
    }

    validateForm = async ()=>{
        this.setState({
            isValidating: true
        })

        const notRequired = [
            "typeOfEntity",
            "admittedProducerCode",
            "addressLine2",
            "error",
            "isValidating",
            "termsAndConditionsVisible"
        ]

        let valid = true
        let error = "Please complete all required fields"

        for (const [key, value] of Object.entries(this.state)) {
            if(!notRequired.includes(key)){
                if(!value){
                    valid = false
                    break
                }
            }
        }

        if(this.state.producerCode === "yes" && !this.state.admittedProducerCode){
            valid = false
        }

        if(this.state.typeOfEntity === ""){
            valid = false
        }

        if(valid){
            if(!testValidEmail(this.state.email)){
                valid = false
                error = "Please enter a valid email address"
            }
        }

        if(!valid){
            this.setState({
                error: error
            })
            return false
        }else{
            this.setState({
                error: ""
            })
            return true
        }
    }

    agreeToTerms = ()=>{
        this.setState({
            acceptedTermsAndConditions: true
        })

        this.toggleTermsAndConditions()

        if(this.state.isValidating){
            this.validateForm()
        }
    }

    render(){
        const { authenticationContainer, brokerCompaniesContainer } = this.props
        return(            
            <>
            <div css={componentStyle}>
                <div className="row" css={componentStyle}>
                    <div className="col">
                        <h2>Create An Account</h2>
                        <p className="directions">If you are a first time user for TankAssure please complete all of the information below to create an account. If you already have an account please click the log-in button on the top right of the page to access the system. If you have forgotten your password or username please use the forgot password function on the log-in screen. If you have any further questions please contact us at TankAssure@gaig.com or 888-828-4320.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <h3>Enter Your Agency Information:</h3>
                        
                        <div className="form-group">
                            <label htmlFor="name"
                                   className={(this.state.isValidating && !this.state.name) 
                                    ? "required" : ""}>
                                Agency Name:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                   className="form-control form-control-sm" 
                                   id="name" 
                                   name="name"
                                   value={this.state.name}
                                   onChange={this.updateValue}
                                   required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="typeOfEntity" className="">
                                Type of Entity:<span className="required">*</span></label>
                            <select className="form-control form-control-sm" 
                                    id="typeOfEntity" 
                                    name="typeOfEntity" 
                                    required=""
                                    onChange={this.updateValue}>
                                <option value="">--- Select ---</option>
                                <option value="None">None</option>
                                <option value="Llc">LLC</option>
                                <option value="Llp">LLP</option>
                                <option value="Corporation">Corporation</option>
                                <option value="Partnership">Partnership</option>
                                <option value="SoleProprietor">Sole Proprietor</option>
                            </select>
                        </div>

                        <div className="form-group" css={componentStyle}>
                            <label className={(this.state.isValidating && !this.state.producerCode) 
                                               ? "required" : ""}>
                                Were you provided a GAIG Admitted Producer Code?<span className="required">*</span>
                            </label>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" 
                                    type="radio" 
                                    id="producerCodeYes"
                                    name="producerCode"                                
                                    value="yes"
                                    checked={this.state.producerCode === "yes"}
                                    onChange={this.updateValue} />
                                <label className="form-check-label"
                                    htmlFor="producerCodeYes">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" 
                                    type="radio" 
                                    id="producerCodeNo"
                                    name="producerCode"                                
                                    value="no"
                                    checked={this.state.producerCode === "no"}
                                    onChange={this.updateValue} />
                                <label className="form-check-label"
                                    htmlFor="producerCodeNo">
                                    No
                                </label>
                            </div>
                            {this.state.producerCode === "yes" &&
                                <small className="form-text text-muted">If yes, please enter it below:</small>
                            }
                        </div>

                        {this.state.producerCode === "yes" &&
                            <div className="form-group">
                                <label htmlFor="admittedProducerCode"
                                    className={(this.state.isValidating && 
                                                !this.state.admittedProducerCode &&
                                                this.state.producerCode === "yes") 
                                    ? "required" : ""}>
                                    GAIG Admitted Producer Code:
                                    {this.state.producerCode === "yes" &&
                                        <span className="required">*</span>
                                    }
                                </label>
                                <input type="text" 
                                        className="form-control form-control-sm" 
                                        id="admittedProducerCode" 
                                        name="admittedProducerCode"
                                        value={this.state.admittedProducerCode}
                                        onChange={this.updateValue} />
                            </div>
                        }
                        
                        <div className="form-group">
                            <label htmlFor="addressLine1"
                                   className={(this.state.isValidating && !this.state.addressLine1) 
                                    ? "required" : ""}>
                                Company Address Line 1:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                    className="form-control form-control-sm" 
                                    id="addressLine1" 
                                    name="addressLine1"
                                    value={this.state.addressLine1}
                                    onChange={this.updateValue} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="addressLine2">Company Address Line 2:</label>
                            <input type="text" 
                                    className="form-control form-control-sm" 
                                    id="addressLine2" 
                                    name="addressLine2"
                                    value={this.state.addressLine2}
                                    onChange={this.updateValue} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="city"
                                   className={(this.state.isValidating && !this.state.city) 
                                    ? "required" : ""}>
                                Company City:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                    className="form-control form-control-sm" 
                                    id="city" 
                                    name="city"
                                    value={this.state.city}
                                    onChange={this.updateValue} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="state"
                                   className={(this.state.isValidating && !this.state.state) 
                                   ? "required" : ""}>
                                Company State:<span className="required">*</span>
                            </label>
                            <select className="form-control form-control-sm" 
                                    id="state"
                                    name="state"
                                    value={this.state.state}
                                    onChange={this.updateValue}>                            
                                <option value="">--- Select Company State ---</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="DC">District of Columbia</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="PR">Puerto Rico</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="VI">Virgin Islands</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="postalCode"
                                   className={(this.state.isValidating && !this.state.postalCode) 
                                   ? "required" : ""}>
                                Zip Code:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                    className="form-control form-control-sm" 
                                    id="postalCode" 
                                    name="postalCode"
                                    value={this.state.postalCode}
                                    onChange={this.updateValue} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mainPhone"
                                   className={(this.state.isValidating && !this.state.mainPhone) 
                                    ? "required" : ""}>
                                Main Company Phone Number:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                    className="form-control form-control-sm" 
                                    id="mainPhone" 
                                    name="mainPhone"
                                    value={this.state.mainPhone}
                                    onChange={this.updateValue} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="website"
                                   className={(this.state.isValidating && !this.state.website) 
                                    ? "required" : ""}>
                                Company Web Address:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                    className="form-control form-control-sm" 
                                    id="website" 
                                    name="website"
                                    value={this.state.website}
                                    onChange={this.updateValue} />
                        </div>
                    </div>
                
                    <div className="col-sm-6">
                        <h3>Enter Your Information:</h3>
                            
                        <div className="form-group">
                            <label htmlFor="firstName"
                                   className={(this.state.isValidating && !this.state.firstName) 
                                    ? "required" : ""}>First Name:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                    className="form-control form-control-sm" 
                                    id="firstName" 
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={this.updateValue} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lastName"
                                  className={(this.state.isValidating && !this.state.lastName) 
                                   ? "required" : ""}>
                                Last Name:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                    className="form-control form-control-sm" 
                                    id="lastName" 
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={this.updateValue} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phoneNumber"
                                   className={(this.state.isValidating && !this.state.phoneNumber) 
                                    ? "required" : ""}>
                                Mobile Phone Number:<span className="required">*</span>
                            </label>
                            <input type="text" 
                                    className="form-control form-control-sm" 
                                    id="phoneNumber" 
                                    name="phoneNumber"
                                    value={this.state.phoneNumber}
                                    onChange={this.updateValue} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email"
                                   className={(this.state.isValidating && (!this.state.email || !testValidEmail(this.state.email))) 
                                    ? "required" : ""}>
                                    Email Address (Username):<span className="required">*</span>
                            </label>
                            <input type="email" 
                                    className="form-control form-control-sm" 
                                    id="email" 
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.updateValue}
                                    required />
                        </div>
                        <div className="form-group">
                            <label className={(this.state.isValidating && !this.state.acceptedTermsAndConditions) 
                                   ? "form-check-label required" : "form-check-label"}>
                                Privacy Policy and Terms &amp; Conditions <span className="required">*</span>
                            </label>
                            <button className="btn btn-sm btn-primary"
                                        onClick={this.toggleTermsAndConditions}>
                                    View Terms &amp; Conditions
                            </button>
                            <p className="directions">Please click the above button to review and agree to the Terms &amp; Conditions in order to complete your registration application</p>
                        </div>

                    </div>
                </div>

                {this.state.error !== "" &&
                    <div className="row">
                        <div className="col">
                            <p className="error">{this.state.error}</p>
                        </div>
                    </div>
                }
                
                <div className="row button-group">
                    <div className="col">
                        <button className="btn btn-sm btn-primary float-right"
                                onClick={this.register}
                                disabled={authenticationContainer.state.isLoading}>Submit</button>
                        
                    </div>
                </div>
            </div>

            { this.state.termsAndConditionsVisible &&
                <TermsAndConditions agreeToTerms={this.agreeToTerms}
                                    close={this.toggleTermsAndConditions} />
            }
            </>
        )
    }   
}

export default Register