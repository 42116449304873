/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { css } from "@emotion/react/macro"

class Spinner extends Component {

    render() {

        const componentStyle = css`
            width: 30px;
            height: 30px;
            border: 3px solid ${this.props.color};
            border-bottom: 3px solid rgba(0,0,0,0);
            border-radius: 50%;
            animation: spin 1s linear infinite;
            float: left;

            .spinner--hidden {
                display: none;
            }

            @keyframes spin {
            to {
                transform: rotate(360deg);
            }
        }
        `

        const { color } = this.props

        return(
            <div className={"spinner-"+color} css={componentStyle}></div>
        )
    }
}

export default Spinner
