/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro"
import React from "react"
import history from "../../components/History"
import Locations from "./Locations"
import Coverages from "./Coverages"
import AbovegroundStorageTanks from "./AbovegroundStorageTanks"
import UndergroundStorageTanks from "./UndergroundStorageTanks"
import Total from "./Total"

const componentStyle = css`
    margin-bottom: 60px;

    h3{
        margin-top: 15px;
    }

    .macro-direction{
        margin: 5px 0 0 0;
    }
 
`
class Application2 extends React.Component{

    validateStorageTanks = ()=>{
        const { applicationsContainer } = this.props
        applicationsContainer.validateStorageTanks()
    }
    
    componentDidMount = async ()=>{
        window.scrollTo(0,0)
        
        const { applicationsContainer } = this.props

        if(!applicationsContainer.state.currentApplicationId){
            history.push("/application/1")
        }

        await applicationsContainer.setState({
            isValidating: false,
            error: false
        })

        applicationsContainer.getTotal()
        await applicationsContainer.getCoverageLimits()
        applicationsContainer.getLocationSites()
        applicationsContainer.getTankAges()
        applicationsContainer.getConstructions()
        applicationsContainer.getCapacities()
        applicationsContainer.getContents()
        applicationsContainer.getDeductibles()
        applicationsContainer.getPipeAges()
        applicationsContainer.getUndergroundLeakDetectionTypes()
        applicationsContainer.getUndergroundPipeLineConstructions()
    }
    
    render(){
        const { applicationsContainer } = this.props
        return(
            <div css={componentStyle}>
                <div className="row">
                    <div className="col">
                        <h2>Application for Storage Tank Policy Page 2</h2>
                        <p className="directions">
                            <strong>Directions</strong>: Please input the desired coverage limits below.
                        </p>
                        <p>Items with the red asterisk (<span className="required-example">*</span>) must be completed in order to proceed</p>
                    </div>
                </div>                
                
                <Coverages applicationsContainer={applicationsContainer} />
                <Locations applicationsContainer={applicationsContainer} />
                <AbovegroundStorageTanks applicationsContainer={applicationsContainer} />
                <UndergroundStorageTanks applicationsContainer={applicationsContainer} />                

                {applicationsContainer.state.error &&
                    <div className="row margin-top">
                        <div className="col">
                            <p className="error">{applicationsContainer.state.error}</p>
                        </div>
                    </div>
                }

                <div className="row button-group">
                    <div className="col">
                        <button className="btn btn-sm btn-primary float-right"
                                disabled={applicationsContainer.state.isLoading}
                                onClick={()=>{
                                    this.validateStorageTanks()
                                }}>
                            Next
                        </button>
                        <button className="btn btn-sm btn-primary float-right"
                                disabled={applicationsContainer.state.isLoading}
                                onClick={()=>{
                                    history.push("/application/1")
                                }}>
                            Previous
                        </button>                        
                    </div>
                </div>
            
                <Total applicationsContainer={applicationsContainer} />
                
            </div>
        )
    }   
}

export default Application2