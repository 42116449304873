/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react/macro";
import React from "react";
import history from "../../components/History";
import { getSiteType } from "../../constants/SiteType";
import RequirementQuestion from "./RequirementQuestion";
import EndorsementQuestions from "./EndorsementQuestions";
import Signature from "./Signature";
import Total from "./Total";

const componentStyle = css`
  margin-bottom: 60px;

  h3 {
    margin-top: 30px;
  }

  .addAnother {
    margin-top: 10px;
  }

  table.insuranceFraudWarning {
    border-bottom: none;
    margin-left: 15px;
  }

  table.insuranceFraudWarning td {
    padding: 15px;
  }

  p.new-york-domiciled {
    color: #cc0000;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 15px;
  }

  p.new-york-domiciled a {
    color: #cc0000;
    text-decoration: underline;
  }
`;
class Application3 extends React.Component {
  componentDidMount = async () => {
    window.scrollTo(0, 0);

    const { applicationsContainer } = this.props;

    if (!applicationsContainer.state.currentApplicationId) {
      history.push("/application/1");
      return;
    }

    await applicationsContainer.setState({
      isValidating: false,
      error: false,
    });

    await applicationsContainer.getInsuranceFraudWarningStatements();
    await applicationsContainer.getEndorsementQuestion1Setting();

    if (!applicationsContainer.state.requirementQuestions.length) {
      await applicationsContainer.getRequirementQuestions();
    }
  };

  validatePage3RequiredFields = async () => {
    const { applicationsContainer } = this.props;
    applicationsContainer.submitQuestions();
  };

  render() {
    const { applicationsContainer } = this.props;

    return (
      <div css={componentStyle}>
        <div className="row">
          <div className="col">
            <h2>Application for Storage Tank Policy Page 3</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="directions">
              <strong>Directions</strong>: Please answer each of the following
              questions as it applies to all storage tanks for which this
              application is being made.
            </p>
            <p>
              Items with the red asterisk (
              <span className="required-example">*</span>) must be completed in
              order to proceed
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {applicationsContainer.state.requirementQuestions.map(
              (requirementQuestion, index) => (
                <RequirementQuestion
                  key={"requirementsQuestion-" + index}
                  index={index}
                  requirementQuestion={requirementQuestion}
                  applicationsContainer={applicationsContainer}
                />
              )
            )}
          </div>
        </div>

        {applicationsContainer.state.currentApplication && (
          <EndorsementQuestions applicationsContainer={applicationsContainer} />
        )}

        <div className="row">
          <div className="col">
            <h3>
              Warranty, Authorized Signature, and Continuing Duty to Update
            </h3>
            <p className="info">
              The undersigned is an authorized representative of the prospective
              First Named Insured, and acknowledges that the information
              provided with the Application, including all supplements,
              attachments and replies to underwriter inquiries, and applications
              from other insurance companies which have been submitted to Great
              American and made a part of this application:
            </p>
            <ol>
              <li>
                Will be relied upon by Great American Insurance Companies in
                determining the acceptability of the prospective Insureds and
                the premium amount to be charged;
              </li>
              <li>Are true, accurate and complete; and</li>
              <li>
                Will be considered an integral part of any resultant insurance
                contract.
              </li>
            </ol>
            <p>
              The undersigned further agrees that the prospective Named
              Insured(s) has a continuing duty, through date of policy
              inception, to update this Application, including all supplements,
              attachments and replies to underwriter inquiries.
            </p>
            <p>
              Completion of this application does not bind coverage. The
              applicant's acceptance of the Company's quotation is required
              before the applicant may be bound and a policy issued.
            </p>
          </div>
        </div>

        {applicationsContainer.state.insuranceFraudWarning && (
          <div className="row">
            <div className="col">
              <h3>{applicationsContainer.state.insuranceFraudWarning.title}</h3>
              <p className="info">
                {applicationsContainer.state.insuranceFraudWarning.intro}
              </p>
              <table className="insuranceFraudWarning">
                <tbody>
                  {applicationsContainer.state.insuranceFraudWarning.statements.map(
                    (statement, index) => (
                      <tr key={index}>
                        <td>
                          {statement.state}
                          <br />
                          {statement.fraudWarningNumber}
                        </td>
                        <td>{statement.text}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {applicationsContainer.state.tria !== null &&
          getSiteType() === "Agency" && (
            <Signature applicationsContainer={applicationsContainer} />
          )}

        <div className="row">
          <div className="col">
            {applicationsContainer?.state?.error && (
              <div className="row margin-top">
                <div className="col">
                  <p className="error">{applicationsContainer?.state?.error}</p>
                </div>
              </div>
            )}
          </div>
        </div>

        {applicationsContainer?.state?.currentApplication?.namedInsured
          .state === "NY" && (
          <div className="row">
            <div className="col">
              <p className="new-york-domiciled">
                For New York domiciled submissions, binding and policy issuance
                is to be completed by the Great American storage tank
                underwriting team. At binding please send your bind request and
                client-signed NYFTZ1 endorsement (attached to the quote) to
                Heather Boyd <a href="mailto:hboyd@gaig.com">hboyd@gaig.com</a>.
              </p>
            </div>
          </div>
        )}

        <div className="row button-group">
          <div className="col">
            <button
              className="btn btn-sm btn-primary float-right"
              onClick={this.validatePage3RequiredFields}
              disabled={applicationsContainer.state.isLoading}
            >
              Submit My Application
            </button>
            <button
              className="btn btn-sm btn-primary float-right"
              disabled={applicationsContainer.state.isLoading}
              onClick={() => {
                history.push("/application/2");
              }}
            >
              Previous
            </button>
          </div>
        </div>

        <Total applicationsContainer={applicationsContainer} />
      </div>
    );
  }
}

export default Application3;
